import React, { Component } from 'react';
import { css } from 'astroturf';
import SEO from '../../components/SEO';
import Layout from '../../layout';
import Elephant from './_Elephant';
import About from './_About';
import FeatureList from './_FeatureList';
import CTA from '../../components/CTA';
import Hero from './_Hero';
import PageHeader from '../../components/PageHeader';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="The perfect browser for quality assurance"
          keywords={['']}
          pathname={this.props.location.pathname}
        />
        <PageHeader>
          <svg style={{ width: '200px' }} viewBox="0 0 402 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="1.30562"
              y="29.3615"
              width="57"
              height="66"
              rx="5"
              transform="rotate(-5.22773 1.30562 29.3615)"
              stroke="#415377"
              strokeWidth="2"
            />
            <path
              d="M44.4633 52.2066C44.0055 45.848 38.3921 40.9886 32.0335 41.4464C25.6283 41.9075 20.8156 47.5176 21.2734 53.8762C21.7345 60.2815 27.2979 65.0975 33.7032 64.6364C40.0617 64.1786 44.9245 58.6119 44.4633 52.2066ZM31.9568 59.3105C31.6965 59.6112 31.1822 59.6483 30.8815 59.3879L25.669 54.8756C25.3683 54.6153 25.3312 54.101 25.5916 53.8003L26.5928 52.6942C26.8498 52.3468 27.3173 52.3131 27.6214 52.6202L31.1766 55.654L37.6848 48.136C37.9417 47.7885 38.4093 47.7548 38.7134 48.0619L39.8628 49.0131C40.1635 49.2734 40.2005 49.7877 39.9402 50.0884L31.9568 59.3105Z"
              fill="#58B993"
            />
            <circle
              cx="35.2352"
              cy="81.9207"
              r="3.56794"
              transform="rotate(-5.22773 35.2352 81.9207)"
              stroke="#415377"
              strokeWidth="2"
            />
            <path
              opacity="0.2"
              d="M78.2045 50.5475C78.2045 50.5475 91.8311 46.7054 102.975 45.8487C114.118 44.992 127.745 47.1499 127.745 47.1499"
              stroke="#415377"
              strokeWidth="2"
            />
            <rect x="142.401" y="1" width="129.656" height="94" rx="5" stroke="#415377" strokeWidth="2" />
            <path
              d="M227.243 54.0414C227.243 43.4164 218.493 34.6664 207.868 34.6664C197.165 34.6664 188.493 43.4164 188.493 54.0414C188.493 64.7445 197.165 73.4164 207.868 73.4164C218.493 73.4164 227.243 64.7445 227.243 54.0414ZM205.603 64.3539C205.134 64.8226 204.274 64.8226 203.806 64.3539L195.681 56.2289C195.212 55.7601 195.212 54.9008 195.681 54.432L197.478 52.7133C197.946 52.1664 198.728 52.1664 199.196 52.7133L204.743 58.182L216.462 46.4633C216.931 45.9164 217.712 45.9164 218.181 46.4633L219.978 48.182C220.446 48.6508 220.446 49.5101 219.978 49.9789L205.603 64.3539Z"
              fill="#58B993"
            />
            <line x1="142.745" y1="13.9375" x2="272.057" y2="13.9375" stroke="#415377" strokeWidth="2" />
            <path
              opacity="0.2"
              d="M287.362 44.7053C287.362 44.7053 301.509 44.1583 312.544 45.9329C323.579 47.7074 336.322 52.9939 336.322 52.9939"
              stroke="#415377"
              strokeWidth="2"
            />
            <rect
              x="364.64"
              y="22.8397"
              width="37"
              height="66"
              rx="5"
              transform="rotate(12.255 364.64 22.8397)"
              stroke="#415377"
              strokeWidth="2"
            />
            <path
              d="M382.274 62.7157C383.145 58.556 380.437 54.4126 376.278 53.5411C372.087 52.6631 367.975 55.3773 367.103 59.537C366.225 63.7273 368.909 67.8336 373.099 68.7116C377.259 69.5832 381.396 66.9059 382.274 62.7157ZM372.955 64.9778C372.733 65.1229 372.397 65.0524 372.252 64.8304L369.738 60.983C369.592 60.761 369.663 60.4246 369.885 60.2795L370.729 59.754C370.958 59.5784 371.264 59.6425 371.402 59.895L373.125 62.491L378.674 58.8645C378.903 58.6888 379.209 58.7529 379.347 59.0055L379.91 59.8257C380.055 60.0477 379.984 60.3842 379.762 60.5292L372.955 64.9778Z"
              fill="#58B993"
            />
            <rect
              x="369.23"
              y="29.9765"
              width="7"
              height="7"
              rx="3.5"
              transform="rotate(12.255 369.23 29.9765)"
              stroke="#415377"
              strokeWidth="2"
            />
          </svg>

          <h1>Test many devices at once. Save hours &amp; hours.</h1>
          <h2>
            Speed up your QA process by doing less repetitive work, spending less time on tests and shipping less bugs.
            80% cheaper than your device lab.
          </h2>
        </PageHeader>
        <About />
        <Elephant />
        <FeatureList />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
