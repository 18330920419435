import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import SmallCTA from '../../components/CTA-small-platforms';
import Feature from '../../components/Feature';
import FeatureVideo from '../../components/FeatureVideo';
import Socials from '../../components/Socials';
import Link from '../../components/link';

const styles = css`
  .featureList {
    padding: 0rem 0 5rem;
    text-align: center;

    & > h2 {
      padding: 5rem 2rem 0rem;
      font-weight: 800;
      color: #000409;
      font-size: calc(18px + 0.6vw);
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
    & > p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;

      padding-left: calc(1rem + env(safe-area-inset-left, 0));
      padding-right: calc(1rem + env(safe-area-inset-right, 0));
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & li {
      color: #000;
    }
  }

  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-left: -4px;

    & span {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }

  .badge {
    vertical-align: middle;
    font-size: 0.8rem;
    line-height: 0.8rem;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 3px 5px 1px;
  }

  .socials {
    margin: 1rem auto;
    padding: 0 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 38rem;
    font-weight: 400;
    color: hsl(200, 56.33%, 55.1%);

    max-width: 80rem;
    margin: 2rem auto 0rem;
    font-size: 1rem;

    @media (max-width: 80rem) {
      padding: 0 2rem;
    }

    & svg {
      fill: currentcolor;
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
    }
  }
`;

function FeatureList() {
  return (
    <StaticQuery
      query={photoquery}
      render={(data) => (
        <section className={styles.featureList}>
          <FeatureVideo screenshot={data.panesVideo} type="light" large brow="Multiple panes">
            <h3>Test on the viewports you need.</h3>

            <p>
              Don't test websites screen-by-screen and device-by-device. Get all your viewports in one overview, no
              browser resizing needed.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.syncVideo} type="light" large brow="Synced interactions">
            <h3>And they're all in sync.</h3>
            <p>
              Scrolling, clicking, touch, typing and <strong>even hovers</strong> are synced across all viewports.
            </p>
            <p>Don't test viewports or devices one-by-one, check them all at once.</p>
          </FeatureVideo>

          <Feature screenshot={data.a11ytest} type="light" full brow="Accessibility panel">
            <h3>Automated accessibility test</h3>
            <p>Over 80 different A11y tests. WCAG 2.1 and actionable feedback included.</p>
          </Feature>

          <FeatureVideo screenshot={data.headers} type="light" brow="Outline panel">
            <h3>
              Your page structure visualized
            </h3>
            <p>Find broken links, incorrect header orders, Focus order issues, missing accessible names and more.</p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.metadata1} type="light" large brow="Meta panel">
            <h3>Stop looking through devtools for your meta data.</h3>
            <p>
              All your meta data in a single overview so you can quickly spot issues. Highlights missing values,
              duplicates, too long and too short content.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.metadata2} type="light" brow="Meta panel">
            <h3>Pixel perfect on Social Media</h3>
            <Socials />
            <p>
              Pixel-perfect previews of social media sharing cards, for Twitter, Facebook, Slack, LinkedIn, Discord,
              Telegram, Bluesky and Google Search. In dark and light mode.
            </p>

            <p><Link to="/social-media-previews/">Deep dive into our social media previews</Link></p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.darkmode} type="light" large brow="Emulation">
            <h3>Dark mode, reduced motion &amp; reduced data testing</h3>
            <p>
              Toggle Dark mode, reduced motion and reduced data for any pane with a single click and test it
              side-by-side with the regular site. No need to change your entire OS.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.contrast2} type="light"  brow="Debug tools">
            <h3>Test your color contrast automatically</h3>
            <p>
              Find all elements that don't have enough contrast using the real text and background colors. We'll suggest
              improved colors too!
            </p>
          </FeatureVideo>

          <SmallCTA />

          <Feature gatsbyImg={data.colorblindness} type="light" full brow="Debug tools">
            <h3>Simulate color blindness</h3>

            <p>Simulate 8 different types of color blindness directly in Polypane, side by side.</p>
          </Feature>

          <Feature screenshot={data.dyslexia} type="light" full brow="Debug tools">
            <h3>Simulate visual impairments</h3>
            <p>
              Polypane simulates Far-sightedness, glaucoma and cataracts but also dyslexia, bright sunlight and night
              mode. Easily accessible through the debug tools.
            </p>
          </Feature>

          <Feature screenshot={data.reachability} type="light" full brow="Debug tools">
            <h3>Reachability</h3>
            <p>
              Check your mobile designs on how easy elements are to reach single-handedly. Comes in righty and lefty
              versions.
            </p>
          </Feature>

          <Feature screenshot={data.overflow} type="light" full brow="Layout debugging">
            <h3>Horizontal overflow detection</h3>
            <p>
              Turn layout debugging on and Polypane highlights all elements causing a horizontal overflow, no messing
              around in the devtools needed.
            </p>
          </Feature>

          <Feature gatsbyImg={data.nodetooltip} full type="light" brow="Element inspector">
            <h3>A11y info at your fingertips</h3>
            <p>
              Whether in the element inspector or in the node selection tooltip, Polypane will show you the a11y info
              for each element, like contrast, accessible name, role and warnings for missing attributes.
            </p>
          </Feature>
        </section>
      )}
    />
  );
}

export default FeatureList;

const photoquery = graphql`
  query qapageFeaturesPageQuery {
    panesVideo: file(relativePath: { eq: "videos/marketers/devicewall.mp4" }) {
      publicURL
    }
    syncVideo: file(relativePath: { eq: "videos/marketers/syncedactions.mp4" }) {
      publicURL
    }
    metadata1: file(relativePath: { eq: "videos/marketers/metadata1.mp4" }) {
      publicURL
    }
    metadata2: file(relativePath: { eq: "videos/marketers/metadata2.mp4" }) {
      publicURL
    }
    colorblindness: file(relativePath: { eq: "images/colorblindness.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    contrast: file(relativePath: { eq: "images/image12.gif" }) {
      publicURL
    }
    contrast2: file(relativePath: { eq: "videos/copy-color.mp4" }) {
      publicURL
    }
    a11ytest: file(relativePath: { eq: "images/a11ytest.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    headers: file(relativePath: { eq: "videos/marketers/headers.mp4" }) {
      publicURL
    }
    dyslexia: file(relativePath: { eq: "images/dyslexia.gif" }) {
      publicURL
    }
    reachability: file(relativePath: { eq: "images/reachability.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    nodetooltip: file(relativePath: { eq: "images/node-tooltip.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    overflow: file(relativePath: { eq: "images/overflow.gif" }) {
      publicURL
    }
    devicewall: file(relativePath: { eq: "videos/marketers/devicewall.mp4" }) {
      publicURL
    }
    syncedactions: file(relativePath: { eq: "videos/marketers/syncedactions.mp4" }) {
      publicURL
    }
    debugtools: file(relativePath: { eq: "videos/marketers/debugtools.mp4" }) {
      publicURL
    }
    metadata2: file(relativePath: { eq: "videos/marketers/metadata2.mp4" }) {
      publicURL
    }
    screenshots: file(relativePath: { eq: "videos/marketers/screenshot.mp4" }) {
      publicURL
    }
    darkmode: file(relativePath: { eq: "videos/marketers/darkmode.mp4" }) {
      publicURL
    }
    links: file(relativePath: { eq: "videos/marketers/links.mp4" }) {
      publicURL
    }
    liveVideo: file(relativePath: { eq: "videos/livecss.mp4" }) {
      publicURL
    }
    deviceVideo: file(relativePath: { eq: "videos/devices.mp4" }) {
      publicURL
    }
    breakpointVideo: file(relativePath: { eq: "videos/breakpoints.mp4" }) {
      publicURL
    }
    browseVideo: file(relativePath: { eq: "videos/browse.mp4" }) {
      publicURL
    }
    devtoolsVideo: file(relativePath: { eq: "videos/devtools.mp4" }) {
      publicURL
    }
    liveReloadVideo: file(relativePath: { eq: "videos/live-reload.mp4" }) {
      publicURL
    }
    handoffVideo: file(relativePath: { eq: "videos/handoff.mp4" }) {
      publicURL
    }
    zoomVideo: file(relativePath: { eq: "videos/zooming.mp4" }) {
      publicURL
    }
    modesVideo: file(relativePath: { eq: "videos/modes.mp4" }) {
      publicURL
    }
    referenceVideo: file(relativePath: { eq: "videos/reference.mp4" }) {
      publicURL
    }
    overlaysVideo: file(relativePath: { eq: "videos/overlays.mp4" }) {
      publicURL
    }
    more: file(relativePath: { eq: "images/building_website.svg" }) {
      publicURL
    }
    darklight: file(relativePath: { eq: "images/overview-split.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    devex: file(relativePath: { eq: "images/devtools-extensions.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;
