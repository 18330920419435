import { css } from 'astroturf';
import React from 'react';
import Brow from '../../components/Brow';
import Circle from '../../components/Circle';

const styles = css`
  .punchout {
    font-weight: 800;
    background: linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .ctawrap {
    padding: 0 0 4.5rem;
  }
  .about {
    padding: 0rem 2rem;
    padding-left: calc(1rem + env(safe-area-inset-left, 0));
    padding-right: calc(1rem + env(safe-area-inset-right, 0));
    text-align: center;
    color: #0a1530;
    --white: #fff;
    --fill: #0a1530;

    &.dark {
      color: #fff;

      --white: #0a1530;
      --fill: #fff;
    }

    &.nomargin {
      margin-top: 0;
      padding-top: 4.5rem;
    }

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      max-width: 60rem;
      color: #0a1530;
    }

    & h3 {
      font-weight: 800;
      font-size: clamp(16px, calc(16px + 0.6vw), 20px);
      line-height: 1.25;
      margin: 0.5rem 0;
    }
    & p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;

      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & > div.two {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      max-width: 1400px;
      margin: 0 auto;
      padding: 0;

      @media (min-width: 800px) {
        flex-direction: row;
        gap: 1rem;
      }

      & svg {
        filter: drop-shadow(0 25px 15px rgba(50, 50, 93, 0.25)) drop-shadow(0 10px 30px rgba(103, 178, 111, 0.25));
      }
    }

    & > div.three {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      max-width: 1400px;
      margin: 0 auto;
      padding: 2rem 0;

      & div {
        padding: 2rem 0;
      }
      & p {
        max-width: 22rem;
      }

      @media (min-width: 800px) {
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: row;
      }
    }
  }
`;

function About(props) {
  return (
    <div
      className={[styles.about, props.nomargin ? styles.nomargin : '', props.dark ? styles.dark : ''].join(' ')}
      id="about"
    >
      <div className={styles.three}>
        <div>
          <svg width="137" height="60" viewBox="0 0 137 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="53" height="60" rx="8" fill="var(--fill)" />
            <rect x="60" width="43" height="50" rx="8" fill="var(--fill)" />
            <rect x="110" width="27" height="37" rx="8" fill="var(--fill)" />
          </svg>

          <h3>Test scenarios everywhere</h3>
          <p>
            From mobile screens to 5K monitors, Polypane syncs everything. Run your scenario's synced across all
            devices.
          </p>
        </div>
        <div>
          <svg width="88" height="60" viewBox="0 0 88 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="87" height="60" rx="10" fill="var(--fill)" />
            <path
              d="M22.5 18.5C22.5 21 24.5 23 27 23C29.4375 23 31.5 21 31.5 18.5C31.5 16.0625 29.4375 14 27 14C24.5 14 22.5 16.0625 22.5 18.5ZM38.375 18.625C37.625 17.8125 36.3125 17.8125 35.5625 18.625L30.125 24H23.8125L18.375 18.625C17.625 17.8125 16.3125 17.8125 15.5625 18.625C14.75 19.375 14.75 20.6875 15.5625 21.4375L21.5 27.375V44C21.5 45.125 22.375 46 23.5 46H24.5C25.5625 46 26.5 45.125 26.5 44V37H27.5V44C27.5 45.125 28.375 46 29.5 46H30.5C31.5625 46 32.5 45.125 32.5 44V27.375L38.375 21.4375C39.1875 20.6875 39.1875 19.375 38.375 18.625Z"
              fill="var(--white)"
            />
            <path
              d="M54.5 18.5C54.5 21 56.5 23 59 23C61.4375 23 63.5 21 63.5 18.5C63.5 16.0625 61.4375 14 59 14C56.5 14 54.5 16.0625 54.5 18.5ZM70.375 18.625C69.625 17.8125 68.3125 17.8125 67.5625 18.625L62.125 24H55.8125L50.375 18.625C49.625 17.8125 48.3125 17.8125 47.5625 18.625C46.75 19.375 46.75 20.6875 47.5625 21.4375L53.5 27.375V44C53.5 45.125 54.375 46 55.5 46H56.5C57.5625 46 58.5 45.125 58.5 44V37H59.5V44C59.5 45.125 60.375 46 61.5 46H62.5C63.5625 46 64.5 45.125 64.5 44V27.375L70.375 21.4375C71.1875 20.6875 71.1875 19.375 70.375 18.625Z"
              fill="var(--white)"
            />
          </svg>

          <h3>Find issues at speed</h3>
          <p>Accessibility issues, page structure, contrast issues, missing meta info. Easy to find and report.</p>
        </div>
        <div>
          <svg width="111" height="60" viewBox="0 0 111 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="111" height="60" rx="10" fill="var(--fill)" />
            <rect x="6" y="6" width="49" height="48" rx="6" fill="var(--white)" />
            <rect x="16" y="17" width="29" height="3" fill="var(--fill)" />
            <rect x="16" y="25" width="13" height="3" fill="var(--fill)" />
            <rect x="16" y="33" width="21" height="3" fill="var(--fill)" />
            <rect x="16" y="41" width="16" height="3" fill="var(--fill)" />
            <rect x="68" y="17" width="29" height="3" fill="var(--white)" />
            <rect x="68" y="25" width="13" height="3" fill="var(--white)" />
            <rect x="68" y="33" width="21" height="3" fill="var(--white)" />
            <rect x="68" y="41" width="16" height="3" fill="var(--white)" />
          </svg>

          <h3>Side by side</h3>
          <p>Don't change your entire OS to test a site. Toggle media features like dark mode directly in Polypane.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
